@font-face {
  font-family: 'VW Head';
  src: url('/src/pages/LandingPages/assets/fonts/VWHead-Bold.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Audi Type Screen';
  src: url('/src/pages/LandingPages/assets/fonts/AudiTypeScreen-Bold.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Audi Type Screen';
  src: url('/src/pages/LandingPages/assets/fonts/AudiTypeScreen-Light.ttf')
    format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
